<script>
import { mdiCursorDefaultClickOutline } from "@mdi/js";
import {server} from "@/main";

export default {
  emits: ['updateTable', 'input'],
  data() {
    return {
      server,
      icons: {
        click: mdiCursorDefaultClickOutline,
      },
      showCrutch: false,
      step: 1,
      form: {file: null, responsible: null}
    }

  },
  methods: {
    onFileSelect(evt) {
      this.form.file = evt.target.files[0];
      this.step = 2;
    },
    async selectResponsible() {
      let thisRef = this;
      this.$BX24.selectUser(function (res) {
        thisRef.form.responsible = JSON.stringify(res);
        thisRef.step = 3;
      })
    },
    async sendForm() {
      let data = new FormData();
      data.append('file', this.form.file, 'file');
      if (this.form.responsible) {
        data.append('responsible', this.form.responsible);
      }
      console.log(this.form.responsible)
      const result = await fetch(`${this.server}import/tasks/`, {
        method: "POST",
        body: data,
      }).then(res => res.json())
      this.$emit('updateTable', result.tasks)
      this.$refs.addressErrors.innerHTML = result.error_address_list
      this.step = 4;
    }
  },
  props: {
    value: { type: Boolean, default: false },
  },
  watch: {
    showCrutch(v) {
      if (v) {
        this.step = 1;
        this.form.file = null;
        if (this.$refs.file) this.$refs.file.value = "";
        if (this.$refs.addressErrors) this.$refs.addressErrors.innerHTML = "";
      }
      if (v !== this.value) {
        this.$emit("input", v);
      }
    },
    value(v) {
      if (v !== this.showCrutch) {
        this.showCrutch = v;
      }
    },
    step(v) {
      if (v === 3) {
        this.sendForm()
      }
    }
  },
};
</script>

<template>
    <v-dialog v-model="showCrutch" max-width="500">
        <v-card width="100%">
          <v-card-title class="text-h5">
            Импорт данных о избирателях
          </v-card-title>
          <v-stepper v-model="step" vertical>
            <v-stepper-step :complete="step > 1" step="1">
              Загрузка файла
              <small>Кликните по области для выбора файла</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card
                color="lighten-1"
                elevation="0"
                class="app-import__select-block"
                height="200px"
                @click="$refs.file.click()"
              >
                <v-icon x-large color="blue">
                  {{icons.click}}
                </v-icon>
                <div>
                  Кликните по области для выбора файла
                </div>
              </v-card>
              <input @change="onFileSelect" class="app__hidden-file" type="file" ref="file" hidden>
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2">
              Выберите ответственного
              <small>Выберите ответственного для задач из файла</small>
            </v-stepper-step>

            <v-stepper-content step="2">
               <v-card
                color="lighten-1"
                elevation="0"
                class="app-import__select-block"
                height="200px"
                @click="selectResponsible"
              >
                <v-icon x-large color="blue">
                  {{icons.click}}
                </v-icon>
                <div>
                  Кликните по области для выбора отвественного
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="step > 3" step="3">
              Обновление данных в битрикс и таблице
              <small>Это может занять от 3 до 30 минут в зависимости от размера файла</small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card
                color="lighten-1"
                class="text-center app__card-load"
                height="200px"
              >
                 <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="70"
                />
              </v-card>
            </v-stepper-content>
             <v-stepper-step :complete="step > 4" step="4">
              Адреса которые не нашлись в базе.
              <small>Проверьте корретность этих адресов в файле округа</small>
            </v-stepper-step>

            <v-stepper-content step="4" >
              <div ref="addressErrors"></div>
            </v-stepper-content>
          </v-stepper>
        </v-card>
    </v-dialog>
</template>

<style lang="scss">
    .app {
      &__card-load {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__hidden-file {
        opacity: 0;
        display: none;
      }
    }
</style>