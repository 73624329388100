<script>
import useGeoJsonYMapAdapter from "@/composables/useGeoJsonYMapAdapter";
import {mdiFilterCogOutline} from "@mdi/js";
import TheElectionImportModal from "./components/TheElectionImportModal.vue";
import {server} from "@/main";

export default {
  name: "App",

  data: () => ({
    server,
    tabs: {
      current: null,
      items: ["Таблица", "Карта"],
    },
    icons: {
      filter: mdiFilterCogOutline,

    },
    table: {
      items: [],
      itemsUpdateState: 0,
      header: {
        items: [
          {
            text: "Ответственный",
            sortable: true,
            value: "b24_user",
          },
          {
            text: "Организация/Место жительства",
            sortable: true,
            value: "lom.info",
          },
          {
            text: "Лидер мнений",
            sortable: true,
            value: "lom.fio",
            groupable: true,
          },
          {
            text: "Телефон",
            value: "lom.phone",
          },
          {
            text: "Округ регистрации",
            value: "voter.registrationDistrict",
          },
          {
            text: "Избирательный округ",
            value: "voter.electionDistrict",
          },
          {
            text: "Группа",
            value: "voter.group",
          },
          {
            text: "ФИО избирателя",
            value: "voter.fio",
          },
          {
            text: "Телефон",
            value: "voter.phone",
          },
          {
            text: "Улица",
            value: "voter.street",
          },
          {
            text: "Дом",
            value: "voter.home",
          },
          {
            text: "Квартира",
            value: "voter.apartments",
          },
        ],
        values: [],
        hide: [],
      },
      loading: true,
      search: {
        value: "",
        lazyValue: "",
        lazyTimeout: 0,
      },
      filters: {
        group: [],
        registrationDistrict: [],
        electionDistrict: [],
        status: [],
        preStatus: [],
        postStatus: [],
        responsible: [],
      },
      filtersDisplayTitles: {
        groups: {
          1: {title: "Сотрудник", id: 1},
          2: {title: "Родственник", id: 2},
          3: {title: "Иные", id: 3},
          4: {title: "Территориальные", id: 4},
          5: {title: "Член семьи", id: 5},
        },
        registrationDistricts: {
          2: {title: "ЦО", id: 2},
          1: {title: "ЖО", id: 1},
          3: {title: "СО", id: 3},
          4: {title: "Другие", id: 4},
        },
        status: {
          1: {title: "Голосовал", id: 1, color: "green"},
          2: {title: "Не голосовал", id: 2, color: "red"},
          3: {title: "Нет информации", id: 3, color: "white"},
        },
        preStatus: {
          1: {title: "Точно пойдет и сторонник партии", id: 1, color: "green"},
          2: {title: "Планирует пойти,но не точно и сторонник партии", id: 2, color: "orange"},
          3: {title: "Не пойдет на выборы однозначно", id: 3, color: "red"},
          4: {title: "Поддерживает иную партию", id: 4, color: "red"},
          5: {title: "Нет информации", id: 5, color: "white"},
        },
        postStatus: {
          1: {title: "Проголосовал", id: 1, color: "green"},
          2: {title: "Планирует пойти голосовать", id: 2, color: "orange"},
          3: {title: "Не пойдет голосовать", id: 3, color: "red"},
          4: {title: "Проголосовал, но за иную партию", id: 4, color: "red"},
          5: {title: "Нет информации", id: 5, color: "white"},
        },
        electionDistrict: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
        ],
      },
      responsibleItems: [],
      groupByLom: false,

    },
    drawer: false,
    show: {
      electionImportModal: false,
    },
    mapInitializeBlock: false,
    //
  }),
  components: {
    TheElectionImportModal,
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "Ответственный",
          sortable: true,
          value: "b24_user",
        },
        {
          text: "Организация/Место жительства",
          sortable: true,
          value: "lom.info",
        },
        {
          text: "Лидер мнений",
          sortable: true,
          value: "lom.fio",
          groupable: true,
        },
        {
          text: "Телефон",
          value: "lom.phone",
        },
        {
          text: "Округ регистрации",
          value: "voter.registrationDistrict",
        },
        {
          text: "Избирательный округ",
          value: "voter.electionDistrict",
        },
        {
          text: "Группа",
          value: "voter.group",
        },
        {
          text: "ФИО избирателя",
          value: "voter.fio",
        },
        {
          text: "Телефон",
          value: "voter.phone",
        },
        {
          text: "Результаты предварительного прозвона",
          value: "voter.pre_status",
        },
        {
          text: "Результаты финального прозвона",
          value: "voter.post_status",
        },
        {
          text: "Улица",
          value: "voter.street",
        },
        {
          text: "Дом",
          value: "voter.home",
        },
        {
          text: "Квартира",
          value: "voter.apartments",
        },
      ];
      return headers.filter(v => !this.table.header.hide.includes(v.value))
    },
    tableGroupBy() {
      return this.table.groupByLom ? "lom.fio" : null;
    },
    displayItems() {
      let items = this.table.items;
      // group filter
      items = items.filter((v) => {
        return (
            !this.table.filters.group.length ||
            this.table.filters.group.includes(v.voter.group)
        );
      });
      // registrationDistrict filter
      items = items.filter((v) => {
        return (
            !this.table.filters.registrationDistrict.length ||
            this.table.filters.registrationDistrict.includes(
                v.voter.registrationDistrict
            )
        );
      });
      // electionDistrict filter
      items = items.filter((v) => {
        return (
            !this.table.filters.electionDistrict.length ||
            this.table.filters.electionDistrict.includes(v.voter.electionDistrict)
        );
      });
      // status filter
      items = items.filter((v) => {
        return (
            !this.table.filters.status.length ||
            this.table.filters.status.includes(v.voter.status)
        );
      });

      // preStatus filter
      items = items.filter((v) => {
        return (
            !this.table.filters.preStatus.length ||
            this.table.filters.preStatus.includes(v.voter.pre_status)
        );
      });

      // postStatus filter
      items = items.filter((v) => {
        return (
            !this.table.filters.postStatus.length ||
            this.table.filters.postStatus.includes(v.voter.post_status)
        );
      });
      return items;
    },
  },
  methods: {
    getAddressColor(address) {
      const perc = address.status_all / (address.count / 100);
      return perc < 1 ? "black" : perc < 10 ? "red" : perc < 30 ? "orange" : "green";
    },
    addressListToFeaturesCollection(address_list) {
      const getStatusColor = (address) => {
        return address.count / 2 > address.status_all ? 3 : address.count / 1.5 > address.status_all ? 2 : 1
      }
      let result = {
        type: "FeatureCollection",
        features: []
      }
      for (const address of address_list) {
        result.features.push({
          type: "Feature",
          id: address.id,
          geometry: {
            type: "Point",
            coordinates: [address.lat, address.lon]
          },
          properties: {
            balloonContentHeader: `<font size=2${' style="color: ' + this.getAddressColor(address) + ';"'}><b>${address.address} ${address.district} округ</b></font>`,
            balloonContentBody: `
                <p>Всего жильцов: ${address.count}</p>
                <p>Из них загружено в базу: ${address.status_all} (${(address.status_all / (address.count / 100)).toFixed(1)}%)</p>
                <p>Из них голосовали праймериз: ${address.status_1}</p>
            ` + (address.uk_name || address.uk_phone || address.uk_fio ? `
             <div>
                    <p>Контакты управляющей компании:</p>
                    ${address.uk_name ? ("<p>Название:  " + address.uk_name + "</p>") : ""}
                    ${address.uk_fio ? ("<p>Фио: " + address.uk_fio + "</p>") : ""}
                    ${address.uk_phone ? ("<p>Телефон: " + address.uk_phone + "</p>") : ""}
                <div/>` : "")
          },
          options: {
            zindex: 10,
            statusColor: getStatusColor(address),
          }
        })
      }
      return result
    },
    ymapInitDistrictsPlacemarks(map, placemarks) {
      var districtObjectManager = new this.$ymaps.ObjectManager({clusterize: false, zIndex: 10000,});
      map.geoObjects.add(districtObjectManager)
      districtObjectManager.add(placemarks);

      const hasBalloonData = (objectId) => {
        return districtObjectManager.objects.getById(objectId).properties.balloonContent;
      }

      const fetchDistrictData = (obj) => {
        const district = obj.properties.iconContent;
        return fetch(`${server}map/district/${district}`).then(response => response.json())
      }

      districtObjectManager.objects.events.add('click', function (e) {
        var objectId = e.get('objectId'),
            obj = districtObjectManager.objects.getById(objectId);
        if (hasBalloonData(objectId)) {
          districtObjectManager.objects.balloon.open(objectId);
        } else {
          obj.properties.balloonContent = "Идет загрузка данных...";
          districtObjectManager.objects.balloon.open(objectId);
          fetchDistrictData(obj).then(function (data) {
            obj.properties.balloonContent = data.content;
            districtObjectManager.objects.balloon.setData(obj);
          });
        }
      });
    },
    async ymapInit() {
      if (this.mapInitializeBlock) return;
      this.mapInitializeBlock = true;
      let layer = (await import("./assets/geo.json")).default;
      let placemarks = (await import("./assets/geoMarks.json")).default;
      let adaptedCollection = useGeoJsonYMapAdapter(layer);
      const ymaps = this.$ymaps;
      const housePlacemarksData = await fetch(`${server}map/data`).then(response => response.json());
      const indexedHousePlacemarksData = housePlacemarksData.reduce((obj, v) => {
        obj[v.id] = v
        return obj
      }, {})
      let thisRef = this;
      ymaps.ready(function () {
        const map = new ymaps.Map("ymaptest", {
          center: [51.726056, 36.169532],
          zoom: 11,
        });

        const objectManager = new ymaps.ObjectManager({clusterize: false, zIndex: 10000,});

        objectManager.add(adaptedCollection);
        map.geoObjects.add(objectManager);

        var houseMarkManager = new ymaps.ObjectManager({
          // Чтобы метки начали кластеризоваться, выставляем опцию.
          clusterize: true,
          // ObjectManager принимает те же опции, что и кластеризатор.
          gridSize: 80,
          clusterDisableClickZoom: true,
          zindex: 5,
          clusterIconLayout: 'default#pieChart',
        });
        objectManager.objects.options.set('preset', 'islands#greenDotIcon');
        console.log(objectManager.objects)
        objectManager.clusters.options.set('preset', 'islands#redClusterIcons');
        map.geoObjects.add(houseMarkManager);
        houseMarkManager.add(thisRef.addressListToFeaturesCollection(housePlacemarksData))
        const [[startBoundsLat, startBoundsLon], [endBoundsLat, endBoundsLon]] = map.getBounds()
        let objectsOutsideBounds = []

        houseMarkManager.objects.each((object) => {
          const [lat, lon] = object.geometry.coordinates;
          if (lat >= startBoundsLat && lon >=startBoundsLon && lat <=endBoundsLat && lon <=endBoundsLon) {

          } else {
            objectsOutsideBounds.push(object.id)
          }
          const address = indexedHousePlacemarksData[object.id];
          // if (address.status_all < address.count / 2) {
          houseMarkManager.objects.setObjectOptions(object.id, {
            'preset': 'islands#redClusterIcon',
            iconColor: thisRef.getAddressColor(address),
          })

          // }
        })
       console.log(objectsOutsideBounds)
        thisRef.ymapInitDistrictsPlacemarks(map, placemarks)

      });

    },
    reloadMap() {
      const curr_map = document.getElementById('ymaptest').firstChild;
      curr_map.remove();
      this.mapInitializeBlock = false;
      this.ymapInit()
    },
    statusColor(item) {
      if (item.voter.status === 1) {
        return "color-green"
      } else if (item.voter.status === 2) {
        return "color-red"
      } else {
        return "white"
      }
    },
    clearFilters() {
      for (const filter in this.table.filters) {
        this.table.filters[filter] = [];
      }
      this.table.groupByLom = false;
    },
    onUpdateTable(items) {
      this.table.items = items;
    },
    openContact(contact) {
      let thisRef = this;
      this.$BX24.openPath(`/crm/contact/details/${contact.b24_id}/`, function () {
        thisRef.getUpdates();
      });
    },
    phoneCall(phone) {
      this.$BX24.im.phoneTo(phone)
    },
    openUser(user) {

      this.$BX24.openPath(`/company/personal/user/${user.b24_id}/`, function (res) {
        console.log(`/company/personal/user/${user.b24_id}`, res)
      })
    },
    getUpdates() {
      fetch(`${this.server}updates/?state_id=${this.table.itemsUpdateState}`).then(async response => {
        const responseData = await response.json();
        let itemsToUpd = responseData.tasks;
        let indexedItemsToUpd = {};
        for (const item of itemsToUpd) {
          indexedItemsToUpd[item.id] = item
        }
        this.table.items = this.table.items.map(item => indexedItemsToUpd[item.id] ? indexedItemsToUpd[item.id] : item)
        this.table.itemsUpdateState = responseData.state_id;
      })
    },
  },
  watch: {
    "table.search.lazyValue": {
      handler: function (v) {
        clearTimeout(this.table.search.lazyTimeout);
        this.table.search.lazyTimeout = setTimeout(() => {
          this.table.search.value = v;
        }, 600);
      }
    },
    "table.items": {
      handler: function (v) {
        if (v.length) {
          this.table.responsibleItems = v.reduce((arr, curr) => {
            if (curr.b24_user) arr.push(curr.b24_user)
            return arr
          }, [])
        }
      }
    }
  },
  async mounted() {
    let getDealsApiPoint = `${this.server}tasks/`
    let user_id = 0
    let thisLink = this;
    this.$BX24.callMethod('user.admin', {}, function (res) {
      const isAdmin = res.data();
      if (!isAdmin) {
        thisLink.$BX24.callMethod('user.current', {}, function (res) {
          user_id = res.data()['ID'];
          getDealsApiPoint += "?responsible=" + user_id;
          fetch(getDealsApiPoint).then(async res => {
            const responseData = await res.json()
            console.log(responseData)
            thisLink.table.items = responseData.tasks;
            thisLink.table.itemsUpdateState = responseData.state_id;
            thisLink.table.loading = false;
          })
        })
      } else {
        fetch(getDealsApiPoint).then(async res => {
          const responseData = await res.json();
          console.log(responseData)
          thisLink.table.items = responseData.tasks;
          thisLink.table.itemsUpdateState = responseData.state_id;
          thisLink.table.loading = false;
        })
      }
    });
  },
};
</script>


<template>
  <v-app>
    <v-main class="app-main" :class="{'app-overflow-h': drawer}">
      <v-tabs v-model="tabs.current">
        <v-tab>Таблица</v-tab>
        <v-tab @click="ymapInit">Карта</v-tab>
      </v-tabs>
      <v-container></v-container>
      <v-navigation-drawer
          v-model="drawer"
          temporary
          width="300"
          absolute
          class="app-nav"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Фильтры</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-container fluid class="pa-6">
          <v-row align="center">
            <v-select
                v-model="table.filters.status"
                :items="Object.values(table.filtersDisplayTitles.status)"
                item-value="id"
                item-text="title"
                attach
                chips
                label="Статус"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.responsible"
                :items="table.responsibleItems"
                item-value="id"
                item-text="name"
                attach
                chips
                label="Ответственный"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.group"
                :items="Object.values(table.filtersDisplayTitles.groups)"
                item-value="id"
                item-text="title"
                attach
                chips
                label="Группа влияния"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.registrationDistrict"
                :items="Object.values(table.filtersDisplayTitles.registrationDistricts)"
                item-value="id"
                item-text="title"
                attach
                chips
                label="Округ регистрации"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.electionDistrict"
                :items="table.filtersDisplayTitles.electionDistrict"
                attach
                chips
                label="Избирательный округ"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.preStatus"
                :items="Object.values(table.filtersDisplayTitles.preStatus)"
                item-value="id"
                item-text="title"
                attach
                chips
                label="Статус предварительного прозвона"
                multiple
            >
            </v-select>
            <v-select
                v-model="table.filters.postStatus"
                :items="Object.values(table.filtersDisplayTitles.status)"
                item-value="id"
                item-text="title"
                attach
                chips
                label="Статус финального прозвона"
                multiple
            >
            </v-select>
            <v-switch
                v-model="table.groupByLom"
                label="Группировать по лому"
            ></v-switch>
          </v-row>
          <v-row>
            <v-btn
                @click="clearFilters"
                class="app-drawer__btn"
                width="100%"
                color="secondary"
                elevation="2"
            >
              Сбросить фильтры
            </v-btn>
          </v-row>
        </v-container>
      </v-navigation-drawer>
      <v-tabs-items v-model="tabs.current">
        <v-tab-item>
          <v-app-bar class="app-table__header">
            <div class="d-flex flex fill-height align-center">
              <div class="app__search fill-height align-center">
                <v-text-field
                    label="Поиск"
                    v-model="table.search.lazyValue"
                    hide-details="auto"
                ></v-text-field>
              </div>
              <div class="d-flex flex fill-height align-center">

                <v-btn icon @click="drawer = true">
                  <v-icon :color="drawer ? 'blue' : 'gray'">
                    {{ icons.filter }}
                  </v-icon>
                </v-btn>
                <v-select
                    class="app-table__hhide-input ml-6"
                    v-model="table.header.hide"
                    :items="table.header.items"
                    item-text="text"
                    item-value="value"

                    attach
                    chips
                    label="Скрыть столбцы"
                    multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                      (+{{ table.header.hide.length - index }} других)
                    </span>
                  </template>

                </v-select>

              </div>
              <div class="flex app__imports fill-height">
                <v-btn @click="show.electionImportModal = true">
                  Загрузить данные о избирателях
                </v-btn>
              </div>
            </div>
          </v-app-bar>
          <v-card class="mt-4">
            <v-data-table
                :headers="headers"
                :items="displayItems"
                :search="table.search.value"
                item-key="id"
                big
                :item-class="statusColor"
                :loading="table.loading"
                loading-text="Идет загрузка данных... Пожалуйста подождите"
                :group-by="tableGroupBy"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [50, 100, 200, 500], 'items-per-page-text': 'Отображать по:'}"
            >
              <template #item.b24_user="{ item }">
                <v-card class="app__user rounded-pill flex flex-nowrap align-center pa-2"
                        v-if="item.b24_user"
                        @click="openUser(item.b24_user)"
                >
                  <v-avatar size="45">
                    <img
                        alt="Avatar"
                        :src="item.b24_user.photo"
                    >
                  </v-avatar>
                  <p class="subtitle-2 mx-4 mb-0">
                    {{ item.b24_user.name }}
                  </p>
                </v-card>

              </template>
              <template #item.lom.info="{ item }">
                <div
                    class="app-table__org pa-2"
                    :key="elem"
                    v-for="elem in item.lom.info.split(';')"
                >
                  {{ elem }}
                </div>
              </template>
              <template #item.lom.fio="{ item }">

                <a href="#" @click="openContact(item.lom)">
                  {{ item.lom.fio }}
                </a>

              </template>
              <template #item.voter.fio="{ item }">

                <a href="#" @click="openContact(item.voter)">
                  {{ item.voter.fio }}
                </a>

              </template>
              <template #item.voter.phone="{ item }">

                <a href="#" @click="phoneCall(item.voter.phone)">
                  {{ item.voter.phone }}
                </a>

              </template>
              <template #item.lom.phone="{ item }">

                <a href="#" @click="phoneCall(item.lom.phone)">
                  {{ item.lom.phone }}
                </a>

              </template>
              <template #item.voter.pre_status="{ item }">

                <v-card width="40" height="40" class="rounded-xl"
                        :color="table.filtersDisplayTitles.preStatus[item.voter.pre_status].color"
                >
                </v-card>


              </template>
              <template #item.voter.post_status="{ item }">

                <v-card width="40" height="40" class="rounded-xl"
                        :color="table.filtersDisplayTitles.postStatus[item.voter.post_status].color"
                >
                </v-card>


              </template>

              <template #group.header="{ group, headers, isOpen, toggle }">
                <td :colspan="headers.length">
                  <v-row class="pa-4 app-table__header" elevation="0" gray>
                    <v-btn icon @click="toggle">
                      <v-icon>
                        {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                      </v-icon>
                    </v-btn>
                    <span class="px-2">
                      {{ group }}
                    </span>
                    <v-btn icon @click="table.groupByLom = false">
                      <v-icon> mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </td>
              </template>
              <template #item.voter.group="{ item }">
                {{ table.filtersDisplayTitles.groups[item.voter.group].title }}
              </template>
              <template #item.voter.registrationDistrict="{ item }">
                {{ table.filtersDisplayTitles.registrationDistricts[item.voter.registrationDistrict].title }}
              </template>
              <template #item.voter.status="{ item }">
                <div class="app-table__status-wrapper">
                  <v-card
                      class="app-table__status"
                      :color="table.filtersDisplayTitles.status[item.voter.status].color"
                  >
                  </v-card>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="mb-4 pa-2 mx-2">
          <v-btn color="secondary" @click="reloadMap">Обновить карту</v-btn>
          </v-card>
          <div id="ymaptest"></div>
        </v-tab-item>
      </v-tabs-items>
      <TheElectionImportModal @updateTable="onUpdateTable" v-model="show.electionImportModal"/>
    </v-main>
  </v-app>
</template>


<style lang="scss">
#ymaptest {
  flex: 1;
  min-height: 100vh;
}

.app {
  &__user {
    cursor: pointer;
    user-select: none;
    display: flex !important;
    min-width: max-content;
  }

  &-overflow-h {
    overflow: hidden;
  }

  &__imports {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-import {
    &__select-block {
      border: 1px dashed #2196f3 !important;
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      user-select: none;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

      & > div {
        color: #2196f3 !important;
      }

      &:hover {
        background: #2196f3 !important;
      }

      &:hover > span, &:hover > div {
        color: white !important;
      }
    }
  }

  &-main {
    height: 100vh;
  }

  &-nav {
    height: 100vh;
  }

  &__search {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 20%;
  }

  &-drawer {
    &__btn {
      margin-top: 20px;
    }
  }

  &-table {
    &__hhide-input {
      margin-top: 30px !important;
      max-width: 400px;
    }

    &__header {
      display: flex;
      align-items: center;
      z-index: 4;

      & .v-toolbar__content {
        width: 100%;
      }
    }

    &__org {
      font-size: 12px;

      &:not(:first-child) {
        border-top: 1px solid salmon;
      }
    }

    &__status {
      border-radius: 50% !important;
      width: 40px;
      height: 40px;

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      text-align: center;
    }
  }
}

.color {
  &-green {
    background: #b2ffb5 !important;
  }

  &-red {
    background: #ff8f87 !important;
  }
}
</style>