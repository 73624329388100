const propertiesAdaptInfo = {
  "description": {name: "balloonContent", target: 'properties',},
  "stroke": {name: "strokeColor", target: 'options'},
  "stroke-width": {name: "strokeWidth", target: 'options'},
  "stroke-opacity": {name: "strokeOpacity", target: 'options'},
  "marker-color": {name: "markerColor", target: 'options'}
}

// const geometryParsers = {
//   default: (feature) => {
//     feature.geometry.coordinates = feature.geometry.coordinates.map((v) => [
//       v[1],
//       v[0],
//     ]);
//     let options = {},
//         properties = {};
//     for (let [key, v] of Object.entries(feature.properties)) {
//       if (key in propertiesAdaptInfo) {
//         const info = propertiesAdaptInfo[key];
//         info.target === "properties" ? properties[info.name] = v : options[info.name] = v;
//       } else {
//         options[key] = v;
//       }
//     }
//     feature.options = options;
//     feature.properties = properties;
//     return feature;
//   },
//   Point: (feature) => {
//     feature.geometry.coordinates = feature.geometry.coordinates.map((v) => [
//       v[1],
//       v[0],
//     ]);
//     let options = {preset: 'islands#blueCircleIcon'},
//         properties = {preset: 'islands#blueCircleIcon'};
//     for (let [key, v] of Object.entries(feature.properties)) {
//       if (key in propertiesAdaptInfo) {
//         const info = propertiesAdaptInfo[key];
//         info.target === "properties" ? properties[info.name] = v : options[info.name] = v;
//       } else {
//         options[key] = v;
//       }
//     }
//     feature.options = options;
//     feature.properties = properties;
//     return feature;
//   }
// };

export default function (geoJson) {
  
  return {
    type: "FeatureCollection",
    features: [
      ...geoJson.features.map((feature) => {
        feature.geometry.coordinates = feature.geometry.coordinates.map((v) => [
          v[1],
          v[0],
        ]);
        let options = {},
            properties = {};
        for (let [key, v] of Object.entries(feature.properties)) {
          if (key in propertiesAdaptInfo) {
            const info = propertiesAdaptInfo[key];
            info.target === "properties" ? properties[info.name] = v : options[info.name] = v;
          } else {
            options[key] = v;
          }
        }
        feature.options = options;
        feature.properties = properties;
        return feature;
      },),
    ],
  };
}
